import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

import {
  ConfirmSignUpForm,
  SIGN_UP_STEP,
  SignUpForm,
} from "../components/sign-up";

export const SignUp = () => {
  const [queryParameters] = useSearchParams();
  const isValidStep = z
    .nativeEnum(SIGN_UP_STEP)
    .safeParse(queryParameters.get("step"));
  const isValidEmail = z.string().safeParse(queryParameters.get("email"));
  const [activeStep, setActiveStep] = useState(
    isValidStep.success ? isValidStep.data : SIGN_UP_STEP.SignUp
  );
  const [userEmail, setUserEmail] = useState<string>(
    isValidStep.success && isValidEmail.success ? isValidEmail.data : ""
  );
  const navigate = useNavigate();

  const completeSignUp = () => {
    navigate("/sign-in");
  };

  const switchStep = (step: SIGN_UP_STEP) => {
    switch (step) {
      default:
      case SIGN_UP_STEP.SignUp:
        return (
          <SignUpForm
            nextStep={() => setActiveStep(SIGN_UP_STEP.ConfirmSignUp)}
            setUserEmail={setUserEmail}
            completeSignUp={completeSignUp}
          />
        );
      case SIGN_UP_STEP.ConfirmSignUp:
        return (
          <ConfirmSignUpForm email={userEmail} nextStep={completeSignUp} />
        );
    }
  };

  return switchStep(activeStep);
};
